class HoaGenericDrawer extends HTMLElement {
	private readonly detailsElement: HTMLDetailsElement;
	private readonly summaryElement: HTMLElement;

	private readonly withBodyFixed: boolean = false;

	public constructor() {
		super();

		this.withBodyFixed = this.dataset.fixedBody === "true";
		this.detailsElement = this.querySelector("details");
		this.summaryElement = this.querySelector("details > summary");
		this.validateElements();

		this.detailsElement.addEventListener(
			"toggle",
			this.onToggleDetails.bind(this)
		);

		this.setupCloseButtons();
	}

	private onToggleDetails() {
		if (!this.withBodyFixed) {
			return;
		}

		if (this.detailsElement.open) {
			document.body.classList.add("tw-fixed");
			this.summaryElement.setAttribute("aria-expanded", "true");
		} else {
			document.body.classList.remove("tw-fixed");
			this.summaryElement.setAttribute("aria-expanded", "false");
		}
	}

	private closeDrawer() {
		this.detailsElement.open = false;
	}

	private setupCloseButtons() {
		this.querySelectorAll("button[data-hoa-generic-drawer-close]").forEach(
			(element) => {
				element.addEventListener("click", this.closeDrawer.bind(this));
			}
		);
	}

	private validateElements() {
		if (!this.detailsElement) {
			throw new Error("hoa-generic-drawer requires a <details> element");
		}

		if (!this.summaryElement) {
			throw new Error("hoa-generic-drawer requires a <summary> element");
		}
	}
}

customElements.define("hoa-generic-drawer", HoaGenericDrawer);
